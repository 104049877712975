import { graphql, PageProps } from "gatsby";

import "../components/style.scss";
import React from "react";

import Layout from "../components/layout";

import ProfilePic from "../images/profile_pic.jpg";
import { FaMobileAlt, FaSchool, FaLaptopCode } from "react-icons/fa";

interface IndexPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        siteName: string;
      };
    };
  };
}

export default class IndexPage extends React.Component<IndexPageProps> {
  public render() {
    // const { siteName } = this.props.data.site.siteMetadata.siteName;
    return (
      <Layout>
        <section className="hero is-primary pb-6">
          <div className="hero-body has-text-centered">
            <div className="container">
              <div className="columns is-centered">
                <div className="column">
                  <h1 className="title">Hi, I'm Viet Tran</h1>
                  <h2 className="subtitle">
                    Full Stack Developer, Learner, and Tech Enthusiast
                  </h2>
                  <figure className="image container profilePic ">
                    <img
                      className="is-rounded"
                      src={ProfilePic}
                      alt="Profile Picture"
                    />
                  </figure>
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-three-fifths">
                  <h2 className="subtitle">
                    I am a developer with experience working with both frontend
                    and backend technologies. Over the past few years, I have
                    worked with Angular for the UI and Java Spring Framework on
                    the backend. I am always looking at learning and working
                    with new technologies.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="is-long section">
          <div
            className="container is-centered has-text-centered is-three-fifths"
            style={{ marginTop: "-7rem" }}
          >
            <div className="box">
              <div className="content">
                <div className="columns">
                  <div className="column">
                    <span className="icon is-large">
                      <FaLaptopCode size="3em" />
                    </span>
                    <h1 className="title">Developer</h1>
                    <p>
                      I have been a developer for years and enjoy creating
                      projects from scratch.
                    </p>
                    <p className="has-text-primary subtitle">
                      Progamming Languages:
                    </p>
                    <p>Javascript, Typescript, Java, HTML, CSS</p>
                    <p className="has-text-primary subtitle">
                      Dev Frameworks and Tools
                    </p>
                    <ul className="is-centered">
                      <li>Angular</li>
                      <li>Spring Framework</li>
                      <li>Bootstrap</li>
                      <li>Bulma CSS</li>
                      <li>GIT</li>
                      <li>Pivotal Cloud Foundry</li>
                      <li>Urban Code Deploy</li>
                      <li>Open Source Jenkins</li>
                      <li>Github Enterprise</li>
                    </ul>
                  </div>
                  <div className="column">
                    <span className="icon is-large">
                      <FaSchool size="3em" />
                    </span>
                    <h1 className="title">Education</h1>
                    <p>
                      I am always learning whether in a school environment or on
                      my own.
                    </p>
                    <p className="has-text-primary subtitle">Degrees:</p>
                    <p>
                      <strong>Oklahoma State University</strong>
                      <br />
                      Management Information Systems <br />
                      Option: Information Assurance
                      <br />
                      Bachelors of Science - May 2016
                    </p>
                    <p>
                      <strong>Tulsa Community College</strong>
                      <br />
                      Management Information Systems <br />
                      Associates of Science - May 2014
                    </p>
                    <p className="has-text-primary subtitle">Udemy Courses:</p>
                    <ul>
                      <li>
                        Angular - The Complete Guide (2020 Edition) by
                        Maximillan Schwarzmuller
                      </li>
                      <li>
                        The Modern Angular Bootcamp [2020] by Stephen Grider
                      </li>
                    </ul>
                  </div>
                  <div className="column">
                    <span className="icon is-large">
                      <FaMobileAlt size="3em" />
                    </span>
                    <h1 className="title">Tech Enthusiast</h1>
                    <p>
                      I love keeping up with new tech hardware and software.
                    </p>
                    <p className="has-text-primary subtitle">My Tech Gear:</p>
                    <ul>
                      <li>Samsung Galaxy Note 20 Ultra</li>
                      <li>Pixel Buds (2020)</li>
                      <li>Nintendo Switch</li>
                      <li>Surface Laptop 3 15"</li>
                      <li>Surface Pro</li>
                      <li>Gaming PC - Intel i5 Skylake, Nvidia GTX 1080</li>
                    </ul>
                    <p className="has-text-primary subtitle">
                      {" "}
                      Tech Interessts:
                    </p>
                    <ul>
                      <li>Home Automation</li>
                      <li>Smart Home Tech</li>
                      <li>Smart Assistants</li>
                      <li>Wearables</li>
                      <li>Artifical Intelligence</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
